import { domain, beirut } from "../helpers/route_helper";
import { readCookie, eraseCookie } from "../helpers/cookie_helper";

const fetchWithAuthCheck = async (url, options = {}) => {
  let response = await fetch(url, options);
  if (response.status === 200 || response.status === 422) {
    return response;
  } else if (response.status === 401) {
    alert(`Session expired. Please log back in.`);
    eraseCookie("apiToken");
    eraseCookie("orgID");
    window.location.href = "/partners/sign_in";
    return null;
  } else {
    return response;
  }
};

export const fetchPartnerData = () => {
  const token = readCookie("apiToken");
  const partnerServer = readCookie("partnerServer");

  return fetchWithAuthCheck(`${beirut}/api/fetch_plaid_data?token=${token}`);
};

export const fetchApplications = () => {
  const token = readCookie("apiToken");
  const partnerServer = readCookie("partnerServer");
  if (partnerServer) {
    return fetchWithAuthCheck(`${beirut}/api/fetch_plaid_data?token=${token}`);
  } else {
    return fetchWithAuthCheck(
      `${domain}/partners/api/applications?token=${token}`
    );
  }
};

export const fetchOpenLoans = () => {
  const token = readCookie("apiToken");
  const partnerServer = readCookie("partnerServer");
  if (partnerServer) {
    return fetchWithAuthCheck(`${beirut}/api/fetch_plaid_data?token=${token}`);
  } else {
    return fetchWithAuthCheck(
      `${domain}/partners/api/dashboard?token=${token}`
    );
  }
};

export const loadLoanData = id => {
  const token = readCookie("apiToken");
  const partnerServer = readCookie("partnerServer");
  if (partnerServer) {
    return fetchWithAuthCheck(
      `${beirut}/api/expand_partner_data?token=${token}&id=${id}`
    );
  } else {
    return fetchWithAuthCheck(
      `${domain}/partners/api/load_loan_data?token=${token}&underwriting_decision_id=${id}`
    );
  }
};

export const sponsor = async ud_id => {
  const token = readCookie("apiToken");
  const url = `${domain}/partners/api/sponsor`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ underwriting_decision_id: ud_id, token: token })
  };
  const response = await fetchWithAuthCheck(url, options);
  if (response !== null) {
    return response.json();
  }
};

export const deny = async ud_id => {
  const token = readCookie("apiToken");
  const url = `${domain}/partners/api/deny`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ underwriting_decision_id: ud_id, token: token })
  };
  const response = await fetchWithAuthCheck(url, options);
  if (response !== null) {
    return response.json();
  }
};

export const updateAmount = async (ud_id, newAmount) => {
  const token = readCookie("apiToken");
  const url = `${domain}/partners/api/update_amount`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      underwriting_decision_id: ud_id,
      amount: newAmount,
      token: token
    })
  };
  const response = await fetchWithAuthCheck(url, options);
  if (response !== null) {
    return response.json();
  }
};

export const updateTerm = async (ud_id, newTerm) => {
  const token = readCookie("apiToken");
  const url = `${domain}/partners/api/update_term`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      underwriting_decision_id: ud_id,
      term: newTerm,
      token: token
    })
  };
  const response = await fetchWithAuthCheck(url, options);
  if (response !== null) {
    return response.json();
  }
};

export const updateComment = async (ud_id, newComment) => {
  const token = readCookie("apiToken");
  const url = `${domain}/partners/api/update_comment`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      underwriting_decision_id: ud_id,
      comment: newComment,
      token: token
    })
  };
  const response = await fetchWithAuthCheck(url, options);
  if (response !== null) {
    return response.json();
  }
};

export const recordCSVDOwnload = async id => {
  const token = readCookie("apiToken");
  const url = `${beirut}/api/record_csv_download`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id: id,
      token: token
    })
  };
  const response = await fetchWithAuthCheck(url, options);
  if (response !== null) {
    return response.json();
  }
};

export const toggleActive = (id, action) => {
  const token = readCookie("apiToken");
  const url = `${beirut}/api/toggle_partner_data_active`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      id: id,
      toggle: action,
      token: token
    })
  };
  const response = fetchWithAuthCheck(url, options);
  if (response !== null) {
    return response;
  }
};
