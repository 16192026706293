import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Login from "./Login";
import NavBar from "./NavBar";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import Applications from "./Applications";
import Dashboard from "./Dashboard";
import PartnerDataDashboard from "./PartnerDataDashboard";
import PlaidApplication from "./PlaidApplication";
import {
  readCookie,
  createCookie,
  eraseCookie
} from "../helpers/cookie_helper";
import { domain } from "../helpers/route_helper";
import { ProtectedRoute } from "../util/route_util";
import "../styles/css/App.css";
import "../styles/css/navbar.css";
import "../styles/css/styles.css";
import "../styles/css/loan_application.css";
import "../styles/css/custom.css";
import "../styles/css/glazed.css";

function App() {
  const loggedIn = () => {
    let token = readCookie("apiToken");
    return Boolean(token);
  };

  const partnerServer = readCookie("partnerServer");

  useEffect(() => {
    window.$ = require("jquery");
    return function cleanup() {
      delete window.jQuery;
      delete window.$;
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/partners/sign_in">
            <Login />
          </Route>
          <Route exact path="/partners/password/new">
            <ResetPassword />
          </Route>
          <Route
            exact
            path="/partners/password/edit"
            component={ChangePassword}
          />
          <ProtectedRoute
            exact
            path="/partners/applications"
            loggedIn={loggedIn}
            component={Applications}
          />
          <ProtectedRoute
            exact
            path="/partners/dashboard"
            loggedIn={loggedIn}
            component={partnerServer ? PartnerDataDashboard : Dashboard}
          />

          <Route path="/bank_verification">
            <PlaidApplication />
          </Route>

          <Route exact path="/">
            {loggedIn() ? (
              <Redirect to="/partners/applications" />
            ) : (
              <Redirect to="/partners/sign_in" />
            )}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
