import React, { useState, useEffect } from "react";

export default function Transaction(props) {
  return (
    <tr
      class={`movement-${props.application.id} ${props.transaction.trans_line}`}
    >
      <td class="date" field="date">
        {props.transaction.date}
      </td>
      <td field="name">{props.transaction.name}</td>
      <td field="location">
        {props.transaction.location.city
          ? props.transaction.location.city + ", "
          : ""}
        {props.transaction.location.state
          ? props.transaction.location.state
          : ""}
      </td>
      <td field="type">{props.transaction.account_name}</td>
      <td field="type_controller" style={{ display: "none" }}>
        {props.transaction.type.toString()}
      </td>
      <td field="amount">${props.transaction.amount.toFixed(2)}</td>
      <td field="balance">
        <div class="depository">
          {props.transaction.account_balance.toFixed(2)}
        </div>
        <div class="account invisible">
          {props.transaction.account_balance.toFixed(2)}
        </div>
      </td>
    </tr>
  );
}
