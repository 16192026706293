import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";

export default function ResetPassword(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [notice, setNotice] = useState(null);

  const reset_request = () =>
    window.$.ajax({
      method: "PUT",
      url: "http://localhost/partners/api/reset_password",
      data: { email }
    })
      .then(res =>
        setNotice(
          "You will receive an email with instructions on how to reset your password in a few minutes."
        )
      )
      .catch(err => setError(err.responseJSON.errors[0]));

  return (
    <body className="blackbg">
      <NavBar notice={notice} />
      <div class="content-container application">
        <div class="content">
          <h1>Forgot your password?</h1>

          <div class="form-container">
            <div id="new_user_form">
              <div id="new_user_registration">
                <h2>Password recovery</h2>
                <div class="form_header">Please fill in the below:</div>

                <div class="formwrap group">
                  <form className="new_partner" id="new_partner">
                    {error ? (
                      <div className="application_errors reset_password">
                        Please make the changes below:
                        <ul>
                          <li>{error}</li>
                        </ul>
                      </div>
                    ) : null}
                    <div className="formfull">
                      <input
                        className="text_input extended"
                        type="text"
                        placeholder="Email Address"
                        name="partner[email]"
                        id="partner_email"
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="clear"></div>
                    <div className="button primary" onClick={reset_request}>
                      Email Password Reset
                    </div>
                  </form>
                </div>

                <div class="form_footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
