import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

export const ProtectedRoute = ({
  component: Component,
  path,
  loggedIn,
  exact,
  componentProps
}) => (
  <Route
    path={path}
    exact={exact}
    render={props =>
      loggedIn() ? (
        <Component {...props} {...componentProps} />
      ) : (
        <Redirect to="/partners/sign_in" />
      )
    }
  />
);
