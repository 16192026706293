import React, { useState, useEffect } from "react";
import {
  readCookie,
  createCookie,
  eraseCookie
} from "../helpers/cookie_helper";
import { domain, beirut } from "../helpers/route_helper";

export default function Header(props) {
  var headerCompanyLogo = <img src="/logos/fig-36-logo-teal.png" />;
  const [orgID, setOrgID] = useState(readCookie("orgID"));

  if (!readCookie("partnerServer")) {
    switch (props.orgID || orgID) {
      case "1":
        headerCompanyLogo = (
          <img src="https://fig-static-images.s3.us-east-2.amazonaws.com/logos/mam-logo.png" />
        );
        break;
      case "2":
        headerCompanyLogo = (
          <img src="https://fig-static-images.s3.us-east-2.amazonaws.com/logos/ccdallas_logo_white.png" />
        );
        break;
      case "3":
        headerCompanyLogo = (
          <img src="https://fig-static-images.s3.us-east-2.amazonaws.com/logos/serv_logo_fl.png" />
        );
        break;
      case "4":
        headerCompanyLogo = (
          <img src="https://fig-static-images.s3.us-east-2.amazonaws.com/community-loans/section-welcome/citysquare.png" />
        );
        break;
      case "5":
        headerCompanyLogo = (
          <img src="https://fig-static-images.s3.us-east-2.amazonaws.com/community-loans/section-welcome/wesley.png" />
        );
        break;
      case "38":
        headerCompanyLogo = (
          <img src="https://fig-static-images.s3.us-east-2.amazonaws.com/community-loans/section-welcome/foundcom.png" />
        );
        break;
      case "71":
        headerCompanyLogo = (
          <img src="https://fig-static-images.s3.us-east-2.amazonaws.com/community-loans/section-welcome/access-ventures.png" />
        );
        break;
      default:
        headerCompanyLogo = <img src="/logos/fig-36-logo-teal.png" />;
    }
  }

  const signOut = () => {
    let url;
    const token = readCookie("apiToken");
    if (readCookie("partnerServer")) {
      url = `${beirut}/api/partners/sign_out?token=${token}`;
    }
    window.$.ajax({
      method: "DELETE",
      url: url ? url : `${domain}/partners/api/partners/sign_out?token=${token}`
    })
      .then(res => {
        eraseCookie("apiToken");
        eraseCookie("orgID");
        eraseCookie("partnerServer");
        window.location = "/partners/sign_in";
      })
      .catch(error => {});
  };

  return (
    <div className="header fixed">
      <div className="header-fig">
        <div className="header-fig__logo">
          <img src="/logos/fig-36-logo-teal.png" />
        </div>
        <div className="header-fig__titles">
          <div className="header-fig__titles--title">{props.title}</div>
          <div className="header-fig__titles--subtitle">{props.subtitle}</div>
        </div>
      </div>
      <div className="header-company">{headerCompanyLogo}</div>

      <div className="navright">
        <nav className="nav-main">
          {readCookie("partnerServer") ? null : window.location.href.includes(
              "dashboard"
            ) ? (
            <li className="white">
              <a href="/partners/applications">Application Review</a>
            </li>
          ) : (
            <li className="white">
              <a href="/partners/dashboard">Open Loans</a>
            </li>
          )}
          <li className="white">
            <a onClick={signOut}>Sign Out</a>
          </li>
        </nav>
      </div>
    </div>
  );
}
