import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";

export default function ChangePassword(props) {
  const [error, setError] = useState(null);
  const [notice, setNotice] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const reset_password_token = new URLSearchParams(props.location.search).get(
    "reset_password_token"
  );

  const change_password = () =>
    window.$.ajax({
      method: "PUT",
      url: "http://localhost/partners/api/partners/password",
      data: {
        info: {
          reset_password_token,
          password,
          password_confirmation: passwordConfirmation
        }
      }
    })
      .then(res => props.history.push("/"))
      .catch(err => setError(err.responseJSON.errors[0]));

  const check_password = () => {
    var re = /(?=.{8,})(?=.*[0-9])(?=.*[a-zA-Z])/;
    const valid_password = re.test(String(password).toLowerCase());

    if (valid_password) {
      if (password === passwordConfirmation) {
        return true;
      }
      setError("Passwords do not match.");
      return false;
    } else {
      setError(
        "Invalid password. Passwords must be 8 characters long and contain at least 1 digit."
      );
      return false;
    }
  };

  return (
    <body className="blackbg">
      <NavBar notice={notice} />
      <div class="content-container application">
        <div class="content">
          <h1>Change your password</h1>
          <div class="form-container">
            <div class="application_errors invisible">
              <div class="login_msg">
                <p class="message">placeholder</p>
              </div>
            </div>
            <div id="new_user_form">
              <div id="new_user_registration">
                <h2>Password reset</h2>
                <div class="form_header">
                  Please enter your new password below:
                </div>

                <div class="formwrap group">
                  <form className="new_partner" id="new_partner">
                    {error ? (
                      <div className="application_errors reset_password">
                        Please make the changes below:
                        <ul>
                          <li>{error}</li>
                        </ul>
                      </div>
                    ) : null}
                    <div className="formfull">
                      <input
                        className="text_input extended"
                        type="password"
                        placeholder="New Password"
                        name="partner[email]"
                        id="partner_email"
                        onChange={e => setPassword(e.target.value)}
                      />
                      <input
                        className="text_input extended"
                        type="password"
                        placeholder="Confirm New Password"
                        name="partner[email]"
                        id="partner_email"
                        onChange={e => setPasswordConfirmation(e.target.value)}
                      />
                    </div>
                    <div className="clear"></div>
                    <div
                      className="button primary"
                      onClick={() => {
                        if (check_password()) {
                          change_password();
                        }
                      }}
                    >
                      Submit
                    </div>
                  </form>
                </div>

                <div class="form_footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
