import React, { useState, useEffect } from "react";
import Indicator from "./Indicator";
import PartnerDataInfo from "./PartnerDataInfo";
import Editable from "./Editable";
import Loader from "react-loader";
import { expandInfo } from "../helpers/applications.js";
import { loadLoanData, toggleActive } from "../util/partner_util";
import cloneDeep from "lodash.clonedeep";

export default function PartnerData(props) {
  const [rawTransactionData, setRawTransactionData] = useState(null);
  const [formattedTransactionData, setFormattedTransactionData] = useState(
    null
  );
  const [indicators, setIndicators] = useState(null);
  const [activeTab, setActiveTab] = useState("graph");
  const [activeData, setActiveData] = useState(props.partnerData.active);
  const [actionLoading, setActionLoading] = useState(false);

  const expandedInfo = props.expandedInfoId === props.partnerData.id;

  useEffect(() => {
    if (expandedInfo && !formattedTransactionData) {
      loadLoanData(props.partnerData.id)
        .then(response => {
          if (response.ok) {
            response
              .clone()
              .json()
              .then(res => {
                let formatted_loan_data = calculateLoanBalance(
                  res.loan_data,
                  "Depository"
                );
                setRawTransactionData(res.loan_data);
                setFormattedTransactionData(formatted_loan_data);
              });
          } else {
            alert(
              "Inactive data. Please activate in order to pull data for this item."
            );
          }
        })
        .catch(err => alert(err));
    }
  }, [expandedInfo]);

  const togglePartnerDataActive = (id, action) => {
    setActionLoading(true);
    toggleActive(id, action).then(response => {
      if (response.ok) {
        setActiveData(!activeData);
      } else {
        alert("An error occured. Please try again");
      }
      setActionLoading(false);
    });
  };

  const onAccountChange = account_id => {
    let calculatedTransactionData = calculateLoanBalance(
      rawTransactionData,
      account_id
    );
    setFormattedTransactionData(calculatedTransactionData);
  };

  const calculateLoanBalance = (loan_data, account_id) => {
    let loan_data_copy = cloneDeep(loan_data);
    console.log(loan_data);
    let account = loan_data_copy.accounts.filter(
      x => x.account_id === account_id
    )[0];
    let account_balance = account.balance;
    let filteredTransactions = loan_data_copy.plaid.transactions.filter(
      x => x.account_id === account_id || account_id == "Depository"
    );
    loan_data_copy.plaid.transactions = filteredTransactions.map(
      (transaction, idx) => {
        transaction["amount"] *= -1;
        if (idx !== 0) {
          account_balance -= filteredTransactions[idx - 1]["amount"];
        }
        transaction["account_balance"] = account_balance;
        return transaction;
      }
    );

    console.log(loan_data_copy === loan_data);

    return loan_data_copy;
  };

  return (
    <div
      className={`loan ${!activeData ? "inactive" : null}`}
      id={`${props.partnerData.id}`}
    >
      <div className={`loan__summary ${expandedInfo ? "selected-loan" : ""}`}>
        <div
          className="loan__summary--score"
          onClick={() => props.expandInfo(props.partnerData.id, activeData)}
        >
          <div className={activeData ? "green" : "grey"}></div>
          <div className="marker"></div>
        </div>

        <div
          className="loan__summary--id"
          onClick={() => props.expandInfo(props.partnerData.id, activeData)}
        >
          {props.partnerData.uuid}
        </div>
        <div className="loan__summary--name">{props.partnerData.name}</div>
        <div className="loan__summary--date">
          {new Date(props.partnerData.date).toLocaleDateString("en-us", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "UTC"
          })}{" "}
          {new Date(props.partnerData.date).toLocaleTimeString("en-us")}
        </div>
        <div></div>
        <div className="loan__summary--comment">
          {props.partnerData.account_type}
        </div>
        <div></div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className={`button primary ${
              activeData ? "deactivate" : "activate"
            }`}
            style={{
              padding: "9px 6px",
              width: "100px",
              fontSize: "15px"
            }}
            onClick={() => {
              let action = activeData ? "deactivate" : "activate";
              let id = props.partnerData.id;
              togglePartnerDataActive(id, action);
            }}
          >
            {activeData ? "Deactivate" : "Activate"}
          </div>
        </div>
      </div>
      <div className={`loan__info ${expandedInfo ? "expanded" : ""}`}>
        <div className="loan__info--indicators">
          <br />
          <h2>Indicators</h2>
          <ul>
            {rawTransactionData
              ? Object.values(rawTransactionData.analysis.indicators).map(x => (
                  <Indicator name={x[0]} value={x[1]} />
                ))
              : null}
          </ul>
        </div>

        <div className="loan__info--charts">
          {expandedInfo && formattedTransactionData ? (
            <PartnerDataInfo
              partnerData={props.partnerData}
              accounts={formattedTransactionData.accounts}
              plaid={formattedTransactionData.plaid}
              onAccountChange={onAccountChange}
              activeTab={activeTab}
            />
          ) : (
            <div className="info" id={`info_${props.partnerData.id}`}>
              <div className="graph" id={`graph_${props.partnerData.id}`}>
                <h2>Account Balance Graph</h2>
                <div
                  className="graph_loading"
                  id={`graph_loading_${props.partnerData.id}`}
                >
                  <img
                    src="/other/preloader_8.gif"
                    style={{ width: "128px", display: "block" }}
                  />
                </div>
              </div>
              <div className="history hidden">
                <h2>Transaction History</h2>
                <div></div>
              </div>
            </div>
          )}
          <div className="tabs">
            <div
              className={`tabs__graph ${
                activeTab === "graph" ? "active-tab" : ""
              } mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect`}
              onClick={() => setActiveTab("graph")}
            >
              <i className="fas fa-chart-area"></i>
            </div>
            <div
              className={`tabs__history ${
                activeTab === "history" ? "active-tab" : ""
              } mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect`}
              onClick={() => setActiveTab("history")}
            >
              <i className="fas fa-history"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
