import React, { useState, useEffect } from "react";
import Transaction from "./Transaction";
import BalanceGraph from "./BalanceGraph";
import { recordCSVDOwnload } from "../util/partner_util";

import * as d3 from "d3";
import _ from "underscore";
import moment from "moment";
const parseRegex = require("regex-parser");

export default function PartnerDataInfo(props) {
  const [transactionPage, setTransactionPage] = useState(0);
  const [transactionTypeFilter, setTransactionTypeFilter] = useState("all");
  const [transactionSearchFilter, setTransactionSearchFilter] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState(
    props.plaid.transactions
  );
  const [selectedAccount, setSelectedAccount] = useState(
    props.accounts[0].account_id
  );

  const transactionsPerPage = 15;

  useEffect(() => {
    props.onAccountChange(selectedAccount);
    setTransactionPage(0);
  }, [selectedAccount]);

  useEffect(() => {
    if (transactionTypeFilter === "all") {
      setFilteredTransactions(props.plaid.transactions);
      setTransactionPage(0);
    } else if (transactionTypeFilter === "green") {
      setFilteredTransactions(
        props.plaid.transactions.filter(x => x.trans_line === "green")
      );
      setTransactionPage(0);
    }
  }, [transactionTypeFilter, props.plaid.transactions]);

  useEffect(() => {
    setFilteredTransactions(
      props.plaid.transactions.filter(x => {
        try {
          let filter = transactionSearchFilter;
          if (filter && filter.startsWith("/")) {
            filter = parseRegex(filter);
          }
          return x.name.match(filter);
        } catch {
          return true;
        }
      })
    );
  }, [transactionSearchFilter]);

  const downloadCSV = () => {
    recordCSVDOwnload(props.partnerData.id).then(response => {
      try {
        if (response.success) {
          let rows = [];
          let headers = Object.keys(filteredTransactions[0]);
          rows.push(headers);
          filteredTransactions.forEach(x => {
            let values = Object.values(x);
            values = values.map(x =>
              typeof x === "object" && x !== null
                ? Object.values(x)
                    .join()
                    .replace(/,/g, " ")
                    .replace(/#/g, " ")
                : typeof x === "string"
                ? x.replace(/,/g, " ").replace(/#/g, " ")
                : x
            );
            rows.push(values);
          });

          let csvContent =
            "data:text/csv;charset=utf-8," +
            rows.map(e => e.join(",")).join("\n");
          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute(
            "download",
            `${
              props.partnerData.uuid
                ? props.partnerData.uuid
                : props.partnerData.id
            }.csv`
          );
          document.body.appendChild(link); // Required for FF
          link.click();
        }
      } catch (error) {
        console.log(error);
        alert("An Error Occured");
      }
    });
  };

  return (
    <div class="info" id={`info_${props.partnerData.uuid}`}>
      <div className={`graph ${props.activeTab === "graph" ? "" : "hidden"}`}>
        <h2>Account Balance Graph</h2>
        <BalanceGraph
          plaid={props.plaid}
          application={{ id: props.partnerData.uuid }}
        />
      </div>
      <div
        className={`history ${props.activeTab === "history" ? "" : "hidden"}`}
      >
        <h2>Transaction History</h2>
        <div>
          <div className="account-selector">
            <select
              name="accounts"
              id={`accounts-${props.partnerData.uuid}`}
              onChange={e => {
                setSelectedAccount(e.target.value);
              }}
            >
              {props.accounts.map(acct => (
                <option value={acct.account_id}>{acct.name}</option>
              ))}
            </select>
          </div>

          <div
            className="account-selector"
            style={{ display: "flex", marginTop: "10px" }}
          >
            <select
              name="transaction-filter"
              onChange={e => {
                setTransactionTypeFilter(e.target.value);
              }}
              style={{ width: "250px", marginRight: "5px" }}
            >
              <option value="all">All</option>
              <option value="green">Income</option>
            </select>
            <input
              style={{ width: "250px", marginRight: "5px" }}
              placeholder="Filter"
              onChange={e => setTransactionSearchFilter(e.target.value)}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="button primary"
                style={{ padding: "5px 3px", width: "100px" }}
                onClick={downloadCSV}
              >
                CSV
              </div>
            </div>
          </div>

          <div
            className="account-table"
            id={`account-table-${props.partnerData.uuid}`}
            page="0"
          >
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th className="name">Name</th>
                  <th className="location">Location</th>
                  <th className="account">Account</th>
                  <th style={{ display: "none" }}>type_controller</th>
                  <th className="amount">Amount</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions
                  .slice(
                    transactionPage * transactionsPerPage,
                    (transactionPage + 1) * transactionsPerPage
                  )
                  .map(transaction => (
                    <Transaction
                      transaction={transaction}
                      application={{ id: props.partnerData.uuid }}
                    />
                  ))}
              </tbody>
            </table>
          </div>
          <div className="account-pagination">
            <button
              disabled={transactionPage === 0}
              className="previous-page pagination mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect"
              onClick={() =>
                setTransactionPage(prevPageNumber => prevPageNumber - 1)
              }
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <h2>
              {transactionPage + 1}/
              {Math.ceil(filteredTransactions.length / transactionsPerPage)}
            </h2>
            <button
              className="next-page pagination mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect"
              disabled={
                transactionPage >=
                Math.floor(filteredTransactions.length / transactionsPerPage)
              }
              onClick={() =>
                setTransactionPage(prevPageNumber => prevPageNumber + 1)
              }
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
