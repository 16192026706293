import $ from "jquery";

$(document).ready(function() {
  // initScrollbar();
  initTips();
  initTabs();
  initTables();
});

export function sponsor(id) {
  // Pull transaction data from remote server
  var dataString = { underwriting_decision_id: id };

  $("#processing_" + id).show();
  $("#actions_" + id).hide();

  $.ajax({
    type: "PATCH",
    url: "/partners/sponsor.js",
    data: dataString,
    success: function(res) {},
    error: function(XMLHttpRequest, textStatus, errorThrown) {}
  });
}

export function deny(id) {
  // Pull transaction data from remote server
  var dataString = { underwriting_decision_id: id };

  $("#processing_" + id).show();
  $("#actions_" + id).hide();

  $.ajax({
    type: "PATCH",
    url: "/partners/deny.js",
    data: dataString,
    success: function(res) {},
    error: function(XMLHttpRequest, textStatus, errorThrown) {}
  });
}

export function expandInfo(id) {
  const loan = $("#" + id);
  const summary = $(loan.children().first());
  const info = loan.children().last();

  const index = Array.prototype.indexOf.call(
    loan.parent().children(),
    loan.get(0)
  );
  const margin = 5;

  if (summary.attr("class").includes("selected-loan")) {
    summary.removeClass("selected-loan");
    info.removeClass("expanded");
  } else {
    $(".selected-loan").removeClass("selected-loan");
    $(".expanded").removeClass("expanded");
    $(".loans__body").animate(
      {
        scrollTop: index * (loan.height() + margin)
      },
      1000
    );
    summary.addClass("selected-loan");
    info.addClass("expanded");
  }

  var dataString = { underwriting_decision_id: id };

  // Pull transaction data from remote server
  $.ajax({
    type: "GET",
    url: "/partners/load_loan_data.js",
    data: dataString,
    success: function(res) {},
    error: function(XMLHttpRequest, textStatus, errorThrown) {}
  });

  // Should this be used?
  // REMOVE - Originally put here when we pre-loaded all data. We no longer do so.
  onAccountTypeChange(id);
}

// Coach admin dashboard comments
export function dashboardCloseComment(id) {
  const loan = $("#" + id);
  const commentSection = $(
    loan
      .children()
      .first()
      .children()
      .get(7)
  );
  const original = $(commentSection.children().get(0));
  const comment_val = $("#comment_" + id).val();
  $("#current_comment_" + id).html(comment_val);

  var dataString = { underwriting_decision_id: id, comment: comment_val };

  $.ajax({
    type: "PATCH",
    url: "/partners/update_comment.js",
    data: dataString,
    success: function(res) {},
    error: function(XMLHttpRequest, textStatus, errorThrown) {}
  });

  original.removeClass("editing");
  commentSection.empty();
  commentSection.append(original);
}

export function dashboardShowCommentEdit(id) {
  const loan = $("#" + id);
  const commentSection = $(
    loan
      .children()
      .first()
      .children()
      .get(7)
  );
  const originalComment = $(commentSection.children().get(0));

  if (originalComment.attr("class").includes("editing")) {
    originalComment.removeClass("editing");
    commentSection.empty();
    commentSection.append(originalComment);
  } else {
    originalComment.addClass("editing");
    const editableComment = $(
      '<input class="comment__input" id="comment_' + id + '" autofocus>'
    ).val(originalComment.text());
    commentSection.append(editableComment.get(0));
    commentSection.append(
      $(
        '<div class="comment__options">' +
          '<button class="mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect" onClick="dashboardCloseComment(' +
          id +
          ')">' +
          '<i class="fa fa-save" onClick=""></i>' +
          "</button>" +
          "</div>"
      ).get(0)
    );
  }
}

// Coach review dashboard comments
export function closeComment(id) {
  const loan = $("#" + id);
  const commentSection = $(
    loan
      .children()
      .first()
      .children()
      .get(6)
  );
  const original = $(commentSection.children().get(0));
  const comment_val = $("#comment_" + id).val();
  $("#current_comment_" + id).html(comment_val);

  var dataString = { underwriting_decision_id: id, comment: comment_val };

  $.ajax({
    type: "PATCH",
    url: "/partners/update_comment.js",
    data: dataString,
    success: function(res) {},
    error: function(XMLHttpRequest, textStatus, errorThrown) {}
  });

  original.removeClass("editing");
  commentSection.empty();
  commentSection.append(original);
}

export function showCommentEdit(id) {
  const loan = $("#" + id);
  const commentSection = $(
    loan
      .children()
      .first()
      .children()
      .get(6)
  );
  const originalComment = $(commentSection.children().get(0));

  if (originalComment.attr("class").includes("editing")) {
    originalComment.removeClass("editing");
    commentSection.empty();
    commentSection.append(originalComment);
  } else {
    originalComment.addClass("editing");
    const editableComment = $(
      '<input class="comment__input" id="comment_' + id + '" autofocus>'
    ).val(originalComment.text());
    commentSection.append(editableComment.get(0));
    commentSection.append(
      $(
        '<div class="comment__options">' +
          '<button class="mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect" onClick="closeComment(' +
          id +
          ')">' +
          '<i class="fa fa-save" onClick=""></i>' +
          "</button>" +
          "</div>"
      ).get(0)
    );
  }
}

export function showAmountEdit(id) {
  const loan = $("#" + id);
  const amountSection = $(
    loan
      .children()
      .first()
      .children()
      .get(4)
  );
  const originalAmount = $(amountSection.children().get(0));

  if (originalAmount.attr("class").includes("editing")) {
    originalAmount.removeClass("editing");
    amountSection.empty();
    amountSection.append(originalAmount);
  } else {
    originalAmount.addClass("editing");
    const editableAmount = $(
      '<input class="amount__input" id="amount_' + id + '" autofocus>'
    ).val(originalAmount.text());
    amountSection.append(editableAmount.get(0));
    amountSection.append(
      $(
        '<div class="amount__options">' +
          '<button class="mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect" onClick="closeAmount(' +
          id +
          ')">' +
          '<i class="fa fa-save" onClick=""></i>' +
          "</button>" +
          "</div>"
      ).get(0)
    );
  }
}

export function closeAmount(id) {
  const loan = $("#" + id);
  const amountSection = $(
    loan
      .children()
      .first()
      .children()
      .get(4)
  );
  const original = $(amountSection.children().get(0));
  const prevAmount = $("#current_amount_" + id).html();
  const amount_val = $("#amount_" + id).val();
  $("#current_amount_" + id).html(amount_val);

  var dataString = { underwriting_decision_id: id, amount: amount_val };

  $.ajax({
    type: "PATCH",
    url: "/partners/update_amount.js",
    data: dataString,
    success: function(res) {},
    error: function(error) {
      $("#current_amount_" + id).html(prevAmount);
      alert(error.responseJSON.error);
    }
  });

  original.removeClass("editing");
  amountSection.empty();
  amountSection.append(original);
}

export function showTermEdit(id) {
  const loan = $("#" + id);
  const termSection = $(
    loan
      .children()
      .first()
      .children()
      .get(5)
  );
  const originalTerm = $(termSection.children().get(0));

  if (originalTerm.attr("class").includes("editing")) {
    originalTerm.removeClass("editing");
    termSection.empty();
    termSection.append(originalTerm);
  } else {
    originalTerm.addClass("editing");
    const editableTerm = $(
      '<input class="term__input" id="term_' + id + '" autofocus>'
    ).val(originalTerm.text());
    termSection.append(editableTerm.get(0));
    termSection.append(
      $(
        '<div class="term__options">' +
          '<button class="mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect" onClick="closeTerm(' +
          id +
          ')">' +
          '<i class="fa fa-save" onClick=""></i>' +
          "</button>" +
          "</div>"
      ).get(0)
    );
  }
}

export function closeTerm(id) {
  const loan = $("#" + id);
  const termSection = $(
    loan
      .children()
      .first()
      .children()
      .get(5)
  );
  const original = $(termSection.children().get(0));
  const prevTerm = $("#current_term_" + id).html();
  const term_val = $("#term_" + id).val();
  $("#current_term_" + id).html(term_val);

  var dataString = { underwriting_decision_id: id, term: term_val };

  $.ajax({
    type: "PATCH",
    url: "/partners/update_term.js",
    data: dataString,
    success: function(res) {},
    error: function(error) {
      $("#current_term_" + id).html(prevTerm);
      alert(error.responseJSON.error);
    }
  });

  original.removeClass("editing");
  termSection.empty();
  termSection.append(original);
}

export function getMovementsByType(id, selectedType) {
  const movementsByType = [];

  // Get movements by selected type
  $(".movement-" + id).each(function(index, movement) {
    const type = $(movement)
      .find('td[field="type_controller"]')
      .text();
    $(movement).removeClass("invisible");

    var depo_field = $(movement).find(".depository");
    var account_field = $(movement).find(".account");

    if (selectedType == "Depository") {
      depo_field.removeClass("invisible");
      account_field.addClass("invisible");
    } else {
      depo_field.addClass("invisible");
      account_field.removeClass("invisible");
    }

    if (type.indexOf(selectedType) >= 0) {
      movementsByType.push($(movement));
    } else {
      $(movement).addClass("invisible");
    }
  });

  return movementsByType;
}

export function onAccountTypeChange(id) {
  const selectedType = $(
    'select[name="accounts"]#accounts-' + id + " option:selected"
  ).val();
  const resultsPerPage = 15;

  const movementsByType = getMovementsByType(id, selectedType);
  const totalMovements = movementsByType.length;

  $("#account-table-" + id).attr("page", 0);

  if (totalMovements > resultsPerPage) {
    $(".next-page").attr("disabled", false);
  }
  $(".previous-page").attr("disabled", true);

  updateAccountType(id);
}

export function updateAccountType(id) {
  const selectedPage = $("#account-table-" + id).attr("page");
  const selectedType = $(
    'select[name="accounts"]#accounts-' + id + " option:selected"
  ).val();

  // alert('#account-table-' + id);
  // alert(selectedType);

  const resultsPerPage = 15;
  const resultsIndex = selectedPage * resultsPerPage;

  const movementsByType = getMovementsByType(id, selectedType);

  const totalMovements = movementsByType.length;

  // Display movements according to page
  $(movementsByType).each(function(index, movement) {
    if (index < resultsIndex || index >= resultsIndex + resultsPerPage) {
      $(movement).addClass("invisible");
    }
  });

  if (totalMovements / (resultsIndex + resultsPerPage) < 1) {
    $(".next-page").attr("disabled", true);
  }
}

export function nextPage(id) {
  const selectedPage = parseInt($("#account-table-" + id).attr("page"));
  const selectedType = $(
    'select[name="accounts"]#accounts-' + id + " option:selected"
  ).val();

  const resultsPerPage = 15;
  const resultsIndex = selectedPage * resultsPerPage;
  const totalMovements = getMovementsByType(id, selectedType).length;

  const goToNextPage = totalMovements / (resultsIndex + resultsPerPage) > 1;

  if (goToNextPage) {
    $("#account-table-" + id).attr("page", selectedPage + 1);
    $(".previous-page").attr("disabled", false);
  }

  updateAccountType(id);
}

export function previousPage(id) {
  const selectedPage = parseInt($("#account-table-" + id).attr("page"));

  $("#account-table-" + id).attr("page", selectedPage - 1);
  $(".next-page").attr("disabled", false);

  if (selectedPage === 1) {
    $(".previous-page").attr("disabled", true);
  }
  updateAccountType(id);
}

// Initializers

export function initTabs() {
  $(".tabs__graph").click(function() {
    $(".active-tab").removeClass("active-tab");
    $(".hidden").removeClass("hidden");
    $(".tabs__graph").addClass("active-tab");
    $(".history").addClass("hidden");
  });

  $(".tabs__history").click(function() {
    $(".active-tab").removeClass("active-tab");
    $(".hidden").removeClass("hidden");
    $(".tabs__history").addClass("active-tab");
    $(".graph").addClass("hidden");
  });
}

export function initTips() {
  const placeholder = {
    suggestion:
      "Fig's general assessment of this client's risk. Scoring is based on available data and content",
    reserve_amount:
      "The amount of your organization's loan loss reserve this loan will take to sponsor",
    weekly_income:
      "Average amount of income the candidate receives weekly. Does not mean they are paid weekly",
    weekly_spend: "Average amount of spending the candidate has weekly",
    monthly_borrow:
      "Total amount of borrowing from recognized high interest lenders in the last month",
    monthly_payment:
      "Total amount of payments to recognized high interest lenders in the last month",
    underwriting_flag:
      "Potential warning flags with this candidate's application.  For a full list of flags and meanings, refer to the Fig36 Workshop Packet.",
    tip: "Welcome to Fig36, this is a placeholder!"
  };

  $(".tip-suggestion").mouseover(function() {
    $(".tip").append(placeholder.suggestion);
  });

  $(".tip-suggestion").mouseout(function() {
    $(".tip").empty();
  });

  $(".tip-reserve_amount").mouseover(function() {
    $(".tip").append(placeholder.reserve_amount);
  });

  $(".tip-reserve_amount").mouseout(function() {
    $(".tip").empty();
  });

  $(".tip-weekly_income").mouseover(function() {
    $(".tip").append(placeholder.weekly_income);
  });

  $(".tip-weekly_income").mouseout(function() {
    $(".tip").empty();
  });

  $(".tip-weekly_spend").mouseover(function() {
    $(".tip").append(placeholder.weekly_spend);
  });

  $(".tip-weekly_spend").mouseout(function() {
    $(".tip").empty();
  });

  $(".tip-monthly_borrow").mouseover(function() {
    $(".tip").append(placeholder.monthly_borrow);
  });

  $(".tip-monthly_borrow").mouseout(function() {
    $(".tip").empty();
  });

  $(".tip-monthly_payment").mouseover(function() {
    $(".tip").append(placeholder.monthly_payment);
  });

  $(".tip-monthly_payment").mouseout(function() {
    $(".tip").empty();
  });

  $(".tip-underwriting_flag").mouseover(function() {
    $(".tip").append(placeholder.underwriting_flag);
  });

  $(".tip-underwriting_flag").mouseout(function() {
    $(".tip").empty();
  });

  $(".tip-income").mouseover(function() {
    $(".tip").append(placeholder.tip);
  });

  $(".tip-income").mouseout(function() {
    $(".tip").empty();
  });

  $(".tip-spend").mouseover(function() {
    $(".tip").append(placeholder.tip);
  });

  $(".tip-spend").mouseout(function() {
    $(".tip").empty();
  });
}

export function initTables() {
  $('select[name="accounts"]').on("change", function() {
    $("option[value=" + this.value + "]", this)
      .attr("selected", true)
      .siblings()
      .removeAttr("selected");
  });
}

/*function initScrollbar(){
	const ps = new PerfectScrollbar('.loans__body');
}*/
