import React, { useState, useEffect } from "react";
import Indicator from "./Indicator";
import Info from "./Info";
import Editable from "./Editable";
import Loader from "react-loader";
import { expandInfo } from "../helpers/applications.js";
import { loadLoanData, sponsor, deny } from "../util/partner_util";
import cloneDeep from "lodash.clonedeep";

export default function Application(props) {
  const [rawTransactionData, setRawTransactionData] = useState(null);
  const [formattedTransactionData, setFormattedTransactionData] = useState(
    null
  );
  const [indicators, setIndicators] = useState(null);
  const [activeTab, setActiveTab] = useState("graph");
  const [actionLoading, setActionLoading] = useState(false);
  const [sponsored, setSponsored] = useState(false);
  const [denied, setDenied] = useState(false);

  const expandedInfo = props.expandedInfoId === props.application.id;

  useEffect(() => {
    if (expandedInfo && !formattedTransactionData) {
      loadLoanData(props.application.id)
        .then(response => {
          if (response) {
            response
              .clone()
              .json()
              .then(res => {
                let formatted_loan_data = calculateLoanBalance(
                  res.loan_data,
                  "Depository"
                );
                setRawTransactionData(res.loan_data);
                setFormattedTransactionData(formatted_loan_data);
              });
          }
        })
        .catch(err => alert(err));
    }
  }, [expandedInfo]);

  const onAccountChange = account_id => {
    let calculatedTransactionData = calculateLoanBalance(
      rawTransactionData,
      account_id
    );
    setFormattedTransactionData(calculatedTransactionData);
  };

  const calculateLoanBalance = (loan_data, account_id) => {
    let loan_data_copy = cloneDeep(loan_data);
    let account = loan_data_copy.accounts.filter(
      x => x.account_id === account_id
    )[0];
    let account_balance = account.balance;
    let filteredTransactions = loan_data_copy.plaid.transactions.filter(
      x => account_id === "Depository" || x.account_id === account_id
    );
    loan_data_copy.plaid.transactions = filteredTransactions.map(
      (transaction, idx) => {
        transaction["amount"] *= -1;
        if (idx !== 0) {
          account_balance -= filteredTransactions[idx - 1]["amount"];
        }
        transaction["account_balance"] = account_balance;
        return transaction;
      }
    );
    return loan_data_copy;
  };

  const sponsorLoan = () => {
    setActionLoading(true);
    sponsor(props.application.id).then(data => {
      if (data["success"]) {
        setSponsored(true);
        setActionLoading(false);
      } else {
        alert(`Error: ${data["error"]}`);
        setActionLoading(false);
      }
    });
  };

  const denyLoan = () => {
    setActionLoading(true);
    deny(props.application.id).then(data => {
      if (data["success"]) {
        setDenied(true);
        setActionLoading(false);
      } else {
        alert(`Error: ${data["error"]}`);
        setActionLoading(false);
      }
    });
  };

  let riskMessage;
  switch (props.application.risk) {
    case "High":
      riskMessage = (
        <p>
          Wait! "High" risk doesn't mean that this isn't a good candidate. Often
          times due to technology issues we may not have the data to properly
          assess a borrower. For these cases, we'll show you what we have for
          you to make your own assessment. Because we don't have the data to
          score this candidate ourselves, we generally require these loans to be
          well covered.
        </p>
      );
      break;
    case "Medium":
      riskMessage = (
        <p>
          {" "}
          "Medium" indicates that based on the data we have we would not approve
          this candidate for a loan on a standalone basis. This may be due to
          concerns around the candidate's economic ability to repay the loan or
          concerning transactions seen in the candidate's bank account. This is
          a great opportunity to use your understanding of the candidate's story
          to modify the risk assessment.
        </p>
      );
      break;
    case "Low":
      riskMessage = (
        <p>
          "Low" indicates that this application shows strong indicators of
          ability to repay. Fig may ultimately decline once it performs external
          assessments on the application but the vast majority of these
          applications ultimately go through to funding. In cases like these, it
          is best to confirm the candidate's indentifying information is correct
          and spot check the numbers.
        </p>
      );
      break;
  }

  return (
    <div className="loan" id={`${props.application.id}`}>
      <div className={`loan__summary ${expandedInfo ? "selected-loan" : ""}`}>
        <div
          className="loan__summary--score"
          onClick={() => props.expandInfo(props.application.id)}
        >
          <div
            className={
              props.application.dashboard.risk === "Low"
                ? "green"
                : props.application.dashboard.risk === "Medium"
                ? "yellow"
                : "red"
            }
          ></div>
          <div className="marker"></div>
        </div>

        <div
          className="loan__summary--id"
          onClick={() => expandInfo(props.application.id)}
        >
          {props.application.loan.id}
        </div>
        <div className="loan__summary--name">{props.application.name}</div>
        <div className="loan__summary--date">
          {new Date(props.application.loan.applied).toLocaleDateString(
            "en-us",
            {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              timeZone: "UTC"
            }
          )}
        </div>
        <div className="loan__summary--amount">
          <Editable
            ud_id={props.application.id}
            value={
              props.application.loan.cash_advanced
                ? props.application.loan.cash_advanced.toFixed(2)
                : "10,000 to 35,000"
            }
            submit={props.updateAmount}
          />
        </div>
        <div className="loan__summary--term">
          <Editable
            ud_id={props.application.id}
            value={props.application.loan.installment_num}
            submit={props.updateTerm}
          />
        </div>
        <div className="loan__summary--comment">
          <Editable
            ud_id={props.application.id}
            value={props.application.loan.comment || "No Comment"}
            submit={props.updateComment}
          />
        </div>

        <div className="loan__summary--referrer">
          <select name="referrers" id="referrers">
            {props.application.referrers.map(referrer => (
              <option
                value={referrer}
                selected={
                  props.application.loan.coach === referrer ? true : null
                }
              >
                {referrer}
              </option>
            ))}
          </select>
        </div>
        <Loader
          loaded={!actionLoading}
          options={{ position: "relative", top: "10px;", color: "#41b29e" }}
        >
          {props.application.organization_name === "Access Ventures" ? null : (
            <div className="loan__summary--actions">
              {sponsored ? (
                "Sponsored!"
              ) : denied ? (
                "Denied"
              ) : (
                <div
                  id={`actions_${props.application.id}`}
                  style={{ display: "block" }}
                >
                  <div className="loan__action">
                    <button
                      className="sponsor mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect"
                      id={`sponsor_${props.application.id}`}
                      onClick={sponsorLoan}
                    >
                      <span>Sponsor</span>
                      <i className="fas fa-check-circle"></i>
                    </button>
                  </div>

                  <div className="loan__action">
                    <button
                      className="deny mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect"
                      id={`deny_${props.application.id}`}
                      onClick={denyLoan}
                    >
                      <span>Deny</span>
                      <i className="fas fa-ban"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Loader>
      </div>
      <div className={`loan__info ${expandedInfo ? "expanded" : ""}`}>
        <div className="loan__info--indicators">
          {props.application.organization_name === "Access Ventures" ? null : (
            <>
              <h2>Loss Reserve</h2>
              <ul>
                <li>
                  <div className="tag">Fig Risk: </div>
                  <div>
                    {props.application.dashboard
                      ? props.application.dashboard.risk
                      : "N/A"}
                  </div>
                </li>
                {riskMessage}
                <li>
                  <div className="tag">Reserve Amount: </div>
                  <div>
                    {(
                      props.application.coverage_percent *
                      parseFloat(props.application.loan.cash_advanced)
                    ).toFixed(2)}
                  </div>
                </li>
              </ul>
            </>
          )}
          <br />
          <h2>Indicators</h2>
          <ul>
            {rawTransactionData
              ? Object.values(rawTransactionData.analysis.indicators).map(x => (
                  <Indicator name={x[0]} value={x[1]} />
                ))
              : null}
          </ul>
        </div>

        <div className="loan__info--charts">
          {expandedInfo && formattedTransactionData ? (
            <Info
              application={props.application}
              accounts={formattedTransactionData.accounts}
              plaid={formattedTransactionData.plaid}
              onAccountChange={onAccountChange}
              activeTab={activeTab}
            />
          ) : (
            <div className="info" id={`info_${props.application.id}`}>
              <div className="graph" id={`graph_${props.application.id}`}>
                <h2>Account Balance Graph</h2>
                <div
                  className="graph_loading"
                  id={`graph_loading_${props.application.id}`}
                >
                  <img
                    src="/other/preloader_8.gif"
                    style={{ width: "128px", display: "block" }}
                  />
                </div>
              </div>
              <div className="history hidden">
                <h2>Transaction History</h2>
                <div></div>
              </div>
            </div>
          )}
          <div className="tabs">
            <div
              className={`tabs__graph ${
                activeTab === "graph" ? "active-tab" : ""
              } mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect`}
              onClick={() => setActiveTab("graph")}
            >
              <i className="fas fa-chart-area"></i>
            </div>
            <div
              className={`tabs__history ${
                activeTab === "history" ? "active-tab" : ""
              } mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect`}
              onClick={() => setActiveTab("history")}
            >
              <i className="fas fa-history"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
