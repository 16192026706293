let dom, curr, partner;

if (process.env.REACT_APP_STAGE === "dev") {
  dom = "http://localhost";
  curr = "http://localhost:3001";
  partner = "http://localhost:3000";
} else if (window.location.host.includes("figloans.com")) {
  dom = "https://app.figloans.com";
  curr = "https://fig36.figloans.com";
  partner = "https://partners.figloans.com";
} else if (window.location.host.includes("stagi-route")) {
  dom =
    "https://fig-web.stagi-route-1l7tcdzxje8cm-1157069208.us-west-2.convox.site";
  curr =
    "https://fig36-web.stagi-Route-1L7TCDZXJE8CM-1157069208.us-west-2.convox.site";
} else {
  dom = "https://app.figloans.com";
  curr = "https://figloans.com";
  partner = "https://partners.figloans.com";
}

export const domain = dom;
export const current = curr;
export const beirut = partner;
