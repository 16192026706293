import React, { useState, useEffect } from "react";
import NavBar from "./NavBar";
import { createCookie, eraseCookie } from "../helpers/cookie_helper";
import { domain, beirut } from "../helpers/route_helper";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notice, setNotice] = useState(null);
  const [error, setError] = useState(null);

  const signIn = partner => {
    let url;
    if (
      partner.partner.email.includes("level") ||
      partner.partner.email.includes("access")
    ) {
      url = `${beirut}/api/partners/sign_in`;
    }
    window.$.ajax({
      method: "POST",
      url: url ? url : `${domain}/partners/api/partners/sign_in`,
      data: partner
    })
      .then(res => {
        console.log(res);
        window.$.ajaxSetup({ data: { token: res.apiToken } });
        createCookie("email", res.email, 2);
        createCookie("apiToken", res.apiToken, 2);
        createCookie("orgID", res.orgID, 2);
        if (res.partnerServer) {
          createCookie("partnerServer", true);
          window.location = "/partners/dashboard";
        } else {
          window.location = "/partners/applications";
        }
      })
      .catch(error => {
        setError("Invalid Email or Password");
        setEmail("");
        setPassword("");
      });
  };

  return (
    <body className="blackbg">
      <NavBar notice={notice} error={error} />
      <div className="content-container application">
        <div className="content">
          <h1>Partner Portal</h1>
          <div className="form-container">
            <div id="new_user_form">
              <div id="new_user_registration">
                <h2>Login</h2>
                <div className="form_header">Please fill in the below:</div>

                <div className="formwrap group">
                  <form className="new_user" id="new_user">
                    <div className="formfull">
                      <input
                        placeholder="Email Address"
                        type="text"
                        className="text_input extended"
                        name="user[email]"
                        id="user_email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="formfull">
                      <input
                        placeholder="Password"
                        type="password"
                        className="text_input extended"
                        name="user[password]"
                        id="user_password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="clear"></div>
                    <br />
                    <div
                      className="button primary"
                      onClick={() => signIn({ partner: { email, password } })}
                    >
                      Submit
                    </div>
                  </form>
                </div>

                <div className="form_footer">
                  <a className="full_link" href="/partners/password/new">
                    Forgot your password?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
