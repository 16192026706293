import React, { useState, useEffect } from "react";
import Header from "./Header";
import Application from "./Application";
import Loader from "react-loader";
import {
  fetchApplications,
  updateAmount,
  updateTerm,
  updateComment
} from "../util/partner_util.js";
import { createCookie, readCookie } from "../helpers/cookie_helper";

import cloneDeep from "lodash.clonedeep";
import $ from "jquery";

export default function Applications(props) {
  const [applications, setApplications] = useState([]);
  const [applicationsLoading, setApplicationsLoading] = useState(true);
  const [orgID, setOrgID] = useState(readCookie("orgID"));
  const [expandedInfoId, setExpandedInfoId] = useState("");

  const loaderOptions = {
    position: "relative",
    color: "#41b29e"
  };

  useEffect(() => {
    fetchApplications()
      .then(response => {
        if (response) {
          response
            .clone()
            .json()
            .then(res => {
              setTimeout(() => {
                if (res.applications) {
                  setApplications(res.applications);
                  setApplicationsLoading(false);
                } else {
                  setApplicationsLoading(false);
                  alert("No new applications");
                }
              }, 2000);
            });
        }
      })
      .catch(err => alert(err));
  }, []);

  const updateApplicationAmount = (ud_id, amount) => {
    updateAmount(ud_id, amount).then(response => {
      if (response["success"]) {
        let tmpApplications = cloneDeep(applications);
        tmpApplications[ud_id]["loan"]["cash_advanced"] = response["new_value"];
        setApplications(tmpApplications);
      } else {
        alert(`Error: ${response["error"]}`);
      }
    });
  };

  const updateApplicationComment = (ud_id, comment) => {
    updateComment(ud_id, comment).then(response => {
      if (response["success"]) {
        let tmpApplications = cloneDeep(applications);
        tmpApplications[ud_id]["loan"]["comment"] = response["new_value"];
        setApplications(tmpApplications);
      } else {
        alert(`Error: ${response["error"]}`);
      }
    });
  };

  const updateApplicationTerm = (ud_id, term) => {
    updateTerm(ud_id, term).then(response => {
      if (response["success"]) {
        let tmpApplications = cloneDeep(applications);
        tmpApplications[ud_id]["loan"]["installment_num"] =
          response["new_value"];
        setApplications(tmpApplications);
      } else {
        alert(`Error: ${response["error"]}`);
      }
    });
  };

  const expandInfo = id => {
    setExpandedInfoId(prevId => (prevId == id ? null : id));
  };

  return (
    <body className="applications-body">
      <div className="operational-dashboard">
        <Header title={"Application Review"} orgID={orgID} />
        <div className="loans">
          <div className="loans__header">
            <div className="loans__header--score"></div>
            <div className="loans__header--id">Loan ID</div>
            <div className="loans__header--name">Client Name</div>
            <div className="loans__header--date">Date</div>
            <div className="loans__header--amount">Amount</div>
            <div className="loans__header--term">Term (months)</div>
            <div className="loans__header--comment">Comment</div>
            <div className="loans__header--referrer">Referrer Name</div>
          </div>

          <div className="loans__body">
            <Loader loaded={!applicationsLoading} options={loaderOptions}>
              {Object.values(applications).map(application => (
                <Application
                  application={application}
                  expandedInfoId={expandedInfoId}
                  expandInfo={expandInfo}
                  updateAmount={updateApplicationAmount}
                  updateComment={updateApplicationComment}
                  updateTerm={updateApplicationTerm}
                />
              ))}
            </Loader>
          </div>
        </div>
        <div className="footer fixed>">
          © 2020 Fig Tech, Inc. All rights reserved
        </div>
      </div>
    </body>
  );
}
