/*
<% content_for(:head) do %>
<%= stylesheet_link_tag 'glazed/style.css', media: 'all', 'data-turbolinks-track': 'reload' %>
<%= javascript_include_tag "page_specific/chart.js" %>
<%= javascript_include_tag "page_specific/partner/applications.js" %>
<script defer src="https://cdnjs.cloudflare.com/ajax/libs/jquery.perfect-scrollbar/1.3.0/perfect-scrollbar.min.js"></script>
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.9/css/all.css" integrity="sha384-5SOiIsAziJl6AWe0HWRKTXlfcSHKmYV4RBF18PPJ173Kzn7jzMyFuTtk8JA7QQG1" crossorigin="anonymous">
<% end %>
*/

import React, { useState, useEffect } from "react";
import Header from "./Header";
import OpenLoan from "./OpenLoan";
import Loader from "react-loader";
import { fetchOpenLoans } from "../util/partner_util.js";

export default function Dashboard(props) {
  const [openLoans, setOpenLoans] = useState([]);
  const [expandedInfoId, setExpandedInfoId] = useState("");
  const [openLoansLoading, setOpenLoansLoading] = useState(true);

  const loaderOptions = {
    position: "relative",
    color: "#41b29e"
  };

  useEffect(() => {
    fetchOpenLoans()
      .then(response => {
        if (response) {
          response
            .clone()
            .json()
            .then(res => {
              setTimeout(() => {
                if (res.loans) {
                  setOpenLoans(res.loans);
                  setOpenLoansLoading(false);
                } else {
                  alert("No new open loans");
                  setOpenLoansLoading(false);
                }
              }, 2000);
            });
        }
      })
      .catch(err => alert(err));
  }, []);

  const expandInfo = id => {
    setExpandedInfoId(prevId => (prevId == id ? null : id));
  };

  return (
    <body className="dashboard-body">
      <div class="operational-dashboard">
        <Header title={"Open Loans"} />

        <div class="loans">
          <div class="loans__header" id="dashboard_headers">
            <div class="loans__header--score"></div>
            <div class="loans__header--id">Loan ID</div>
            <div class="loans__header--name">Client</div>
            <div class="loans__header--date">Applied</div>
            <div class="loans__header--amount">Amount</div>
            <div class="loans__header--currbal">Balance</div>
            <div class="loans__header--nextpay">Next Payment</div>
            <div class="loans__header--comment">Comment</div>
          </div>

          <div class="loans__body">
            <Loader loaded={!openLoansLoading} options={loaderOptions}>
              {openLoans.map(loan => (
                <OpenLoan
                  loan={loan}
                  expandedInfoId={expandedInfoId}
                  expandInfo={expandInfo}
                />
              ))}
            </Loader>
          </div>
        </div>

        <div className="footer fixed>">
          © 2020 Fig Tech, Inc. All rights reserved
        </div>
      </div>
    </body>
  );
}
