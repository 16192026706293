import React, { useState, useEffect } from "react";
import { PlaidLink } from "react-plaid-link";
import Helmet from "react-helmet";
import { useHistory } from "react-router";

import { environment } from "../helpers/plaid_helper";
import { domain } from "../helpers/route_helper";

import NortonSeal from "./NortonSeal";

export default function PlaidApplication(props) {
  const [errors, setErrors] = useState([]);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    if (errors.length > 0) {
      document
        .getElementsByTagName("h2")[0]
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);
  const handleOnSuccess = (token, metadata) => {
    window.$.ajax({
      method: "POST",
      url: `${domain}/partners/api/submit_av_plaid`,
      data: {
        token,
        first_name: firstName,
        last_name: lastName,
        email: email
      }
    });
    window.location = "/community-loans/access-ventures?success=true";
  };
  const handleOnExit = (err, metadata) => {
    setErrors(["You must complete bank verification."]);
  };

  const errorCheck = () => {
    tmpErrors = [];
    if (!firstName) {
      tmpErrors.push("Please enter your first name.");
    }

    if (!lastName) {
      tmpErrors.push("Please enter your last name.");
    }

    if (!email) {
      tmpErrors.push("Please enter your email address.");
    }

    setErrors(tmpErrors);
  };

  let tmpErrors;
  if (errors.length > 0) {
    tmpErrors = (
      <div className="application_errors">
        <p>
          {errors.map(error => (
            <li className="message">{error}</li>
          ))}
        </p>
      </div>
    );
  }

  const link1 = (
    <PlaidLink
      clientName="Fig"
      env={"sandbox"}
      product={["transactions"]}
      webhook="https://app.figloans.com/plaid/webhook_v2"
      onExit={handleOnExit}
      onSuccess={handleOnSuccess}
      id="plaid-link-button"
      className="primary_verification_button"
      publicKey="e18af50fb9a7d6f110ec246b7ff769"
      apiVersion="v2"
    >
      {" "}
      Link Your Bank Account
    </PlaidLink>
  );

  const link2 = null;
  return (
    <div className="access-ventures">
      <div className="content-container application">
        <Helmet>
          <title>Application Bank Verification</title>
        </Helmet>
        <div className="content">
          <h1>
            <div>Fig36</div>
          </h1>

          <div className="form-container">
            <div id="overlay" />

            <div className="application_errors invisible">
              <div className="login_msg">
                <p className="message">placeholder</p>
              </div>
            </div>

            <h2>Bank Verification</h2>
            {tmpErrors}
            <div id="ibv_select">
              <div>
                <div className="form_header">
                  This is the final step to complete your bank account
                  verification.
                </div>
                Please submit your bank account information to connect your bank
                account history to your Growth Loan application. Bank
                verification is required for approval because the Growth Loan
                team makes lending decisions based on the history of your bank
                account instead of your credit score.
                <br /> <br />
                You must have online banking set up in order to link your bank
                account. If you do not have online banking, please contact your
                loan officer through Lenderfit. <br />
                <br />
                <div>
                  <input
                    type="text"
                    placeholder="First Name"
                    onChange={e => setFirstName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    onChange={e => setLastName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Email"
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <br />
                How to Verify:
                <ul>
                  <li>Click the link below to verify your bank</li>
                  <li>
                    {
                      "Use the login information you would use to sign into your bank's online banking"
                    }
                  </li>
                  <br />

                  <div className="form_80per_float_right">
                    <div className="formwrap group">
                      <div id="primary_click">
                        {firstName && lastName ? (
                          link1
                        ) : (
                          <button
                            className="primary_verification_button"
                            onClick={errorCheck}
                          >
                            Link Your Bank Account
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </ul>
                <div className="form_80per_float_right">
                  <div className="form_footer">
                    <NortonSeal />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
