/*
Notes:
1. notices and alerts/errors
2. Fig36 logo
*/

import React, { useState, useEffect } from "react";

export default function Header(props) {
  const [fig36Logo, setFig36Logo] = useState(false);

  return (
    <header className="home" role="banner">
      <div className="content-container">
        <div className="content">
          <div className="group">
            <div id="navbar-mobile-toggle">
              <div className="icons">
                <span id="toggle-normal" data-icon="&#x62"></span>
                <span id="toggle-hover" data-icon="&#xe056"></span>
              </div>
              <img
                src={
                  fig36Logo
                    ? "logos/fig_36_navbar.png"
                    : "https://s3.us-east-2.amazonaws.com/fig-static-images/logos/figlogo_lg.png"
                }
                id="dropdown_logo"
                alt="Fig Tech, Inc"
              />
            </div>
            <a href="/">
              <img
                src={
                  fig36Logo
                    ? "logos/fig_36_navbar.png"
                    : "https://s3.us-east-2.amazonaws.com/fig-static-images/logos/figlogo_lg.png"
                }
                className="logo desktop"
                alt="Fig Tech, Inc"
              />
            </a>
            <nav className="nav-main">
              <div className="clear"></div>

              <div className="desktop" id="subbar">
                <div className="group invisible" id="about_subbar">
                  <ul>
                    {props.loggedIn ? (
                      <li>
                        <a href="/blog">Blog</a>
                      </li>
                    ) : null}
                    <span className="stretch"></span>
                  </ul>
                </div>
                <div className="group invisible" id="info_subbar">
                  <ul>
                    <span className="stretch"></span>
                  </ul>
                </div>
              </div>
            </nav>

            {props.notice || props.error ? (
              <p className={`flash ${props.error ? "alert" : "notice"}`}>
                {props.notice}
                {props.notice && props.error ? <br /> : null}
                {props.error}
              </p>
            ) : null}
          </div>
        </div>

        <div className="content" id="navbar-mobile-bar">
          <div className="group">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <span className="stretch"></span>
            </ul>
          </div>
          <div className="group invisible" id="about_subbar_mobile">
            <ul>
              <span className="stretch"></span>
            </ul>
          </div>
          <div className="group invisible" id="info_subbar_mobile">
            <ul>
              <span className="stretch"></span>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
