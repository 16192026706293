import React, { useState, useEffect } from "react";
import Header from "./Header";
import PartnerData from "./PartnerData";
import Loader from "react-loader";
import { fetchPartnerData } from "../util/partner_util.js";
import { createCookie, readCookie } from "../helpers/cookie_helper";

import cloneDeep from "lodash.clonedeep";
import $ from "jquery";

export default function PartnerDataDashboard(props) {
  const [partnerData, setPartnerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orgID, setOrgID] = useState(readCookie("orgID"));
  const [expandedInfoId, setExpandedInfoId] = useState("");

  const loaderOptions = {
    position: "relative",
    color: "#41b29e"
  };

  useEffect(() => {
    fetchPartnerData()
      .then(response => {
        if (response) {
          response
            .clone()
            .json()
            .then(res => {
              setTimeout(() => {
                if (res.partnerData) {
                  setPartnerData(res.partnerData);
                  setLoading(false);
                } else {
                  setLoading(false);
                  alert("No active data");
                }
              }, 2000);
            });
        }
      })
      .catch(err => alert(err));
  }, []);

  const expandInfo = (id, active) => {
    if (active) {
      setExpandedInfoId(prevId => (prevId == id ? null : id));
    } else {
      alert(
        "Inactive data. Please activate in order to pull data for this item."
      );
    }
  };

  return (
    <body className="applications-body">
      <div className="operational-dashboard">
        <Header title={"Application Review"} orgID={orgID} />
        <div className="loans">
          <div className="loans__header">
            <div className="loans__header--score"></div>
            <div className="loans__header--id">UUID</div>
            <div className="loans__header--name">Client Name</div>
            <div className="loans__header--date">Date</div>
            <div className="loans__header--amount"></div>
            <div className="loans__header--term"></div>
            <div className="loans__header--comment"></div>
            <div className="loans__header--referrer"></div>
          </div>

          <div className="loans__body">
            <Loader loaded={!loading} options={loaderOptions}>
              {Object.values(partnerData)
                .filter(partnerData => partnerData.active)
                .map(partnerData => (
                  <PartnerData
                    partnerData={partnerData}
                    expandedInfoId={expandedInfoId}
                    expandInfo={expandInfo}
                  />
                ))}
              {Object.values(partnerData)
                .filter(partnerData => !partnerData.active)
                .map(partnerData => (
                  <PartnerData
                    partnerData={partnerData}
                    expandedInfoId={expandedInfoId}
                    expandInfo={expandInfo}
                  />
                ))}
            </Loader>
          </div>
        </div>
        <div className="footer fixed>">
          © 2020 Fig Tech, Inc. All rights reserved
        </div>
      </div>
    </body>
  );
}
