import React, { useState, useEffect } from "react";

export default function Editable(props) {
  const [edit, setEdit] = useState(false);
  const [tmpValue, setTmpValue] = useState(props.value);

  useEffect(() => {
    if (!edit) {
      setTmpValue(props.value);
    }
  }, [edit]);
  return edit ? (
    <span>
      <input
        value={tmpValue}
        onChange={e => setTmpValue(e.target.value)}
        style={{ width: "75%" }}
      />
      <br />
      <br />
      <i
        className="fa fa-check fa-2"
        aria-hidden="true"
        onClick={() => {
          props.submit(props.ud_id, tmpValue);
          setEdit(false);
        }}
      ></i>
      <i
        className="fa fa-times fa-2"
        aria-hidden="true"
        style={{ marginLeft: "10px" }}
        onClick={() => {
          setEdit(false);
        }}
      ></i>
    </span>
  ) : (
    <div onClick={() => setEdit(true)}>{props.value}</div>
  );
}
