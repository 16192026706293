import React, { Component } from "react";

export default class NortonSeal extends Component {
  constructor(props) {
    super(props);
    let dn = "www.figloans.com";
    let lang = "en";
    let splash_url = "https://trustsealinfo.websecurity.norton.com";
    let sslcenter_url =
      "https://www.websecurity.symantec.com/security-topics/what-is-ssl-tls-https";

    let u1 =
      splash_url +
      "/splash?form_file=fdf/splash.fdf&dn=" +
      dn +
      "&lang=" +
      lang;

    var u5 = sslcenter_url;
    var sopener;
    this.symcBuySSL = () => {
      var win = window.open(u5, "_blank");
      win.focus();
    };
    this.vrsn_splash = () => {
      if (sopener && !sopener.closed) {
        sopener.focus();
      } else {
        let tbar =
          "location=yes,status=yes,resizable=yes,scrollbars=yes,width=560,height=500";
        var sw = window.open(u1, "VRSN_Splash", tbar);
        if (sw) {
          sw.focus();
          sopener = sw;
        }
      }
    };
    var ver = -1;
    var v_ua = navigator.userAgent.toLowerCase();
    let re = new RegExp("msie ([0-9]{1,}[.0-9]{0,})");
    if (re.exec(v_ua) !== null) {
      ver = parseFloat(RegExp.$1);
    }
    var v_old_ie = v_ua.indexOf("msie") !== -1;
    if (v_old_ie) {
      v_old_ie = ver < 5;
    }
    function v_mact(e) {
      var s;
      if (document.addEventListener) {
        s = e.target.name === "seal";
        if (s) {
          this.vrsn_splash();
          return false;
        }
      } else if (document.captureEvents) {
        var tgt = e.target.toString();
        s = tgt.indexOf("splash") !== -1;
        if (s) {
          this.vrsn_splash();
          return false;
        }
      }
      return true;
    }

    if (v_ua.indexOf("msie") !== -1 && ver >= 7) {
      var plat = -1;
      re = new RegExp("windows nt ([0-9]{1,}[.0-9]{0,})");
      if (re.exec(v_ua) !== null) {
        plat = parseFloat(RegExp.$1);
      }
      if (plat >= 5.1 && plat !== 5.2) {
        document.write("<div style='display:none'>");
        document.write(
          "<img src='https://extended-validation-ssl.websecurity.symantec.com/dot_clear.gif'/>"
        );
        document.write("</div>");
      }
    }
    if (document.addEventListener) {
      document.addEventListener("mouseup", v_mact, true);
    } else {
      if (document.layers) {
        document.captureEvents(Event.MOUSEDOWN);
        document.onmousedown = v_mact;
      }
    }
    function v_resized(pageWidth, pageHeight) {
      if (
        pageWidth !== window.innerWidth ||
        pageHeight !== window.innerHeight
      ) {
        window.history.go(0);
      }
    }
    if (document.layers) {
      let pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;
      window.onresize = v_resized(pageWidth, pageHeight);
    }
  }
  render() {
    return (
      <div className="norton_center">
        <table
          width="135"
          border="0"
          cellPadding="2"
          cellSpacing="0"
          title="Click to Verify - This site chose Symantec SSL for secure e-commerce and confidential communications."
        >
          <tbody>
            <tr>
              <td width="135" align="center" valign="top">
                <br />
                <img
                  name="seal"
                  src="https://s3.us-east-2.amazonaws.com/fig-static-images/other/norton.gif"
                  onContextMenu={() => false}
                  border="0"
                  useMap="#sealmap_medium"
                  alt=""
                />
                <map name="sealmap_medium" id="sealmap_medium">
                  <area
                    alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security"
                    title=""
                    onClick={this.vrsn_splash}
                    shape="rect"
                    coords="0,0,115,58"
                    tabIndex="-1"
                    style={{ outline: "none" }}
                  />
                  <area
                    alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security"
                    title=""
                    onClick={this.vrsn_splash}
                    shape="rect"
                    coords="0,58,63,81"
                    tabIndex="-1"
                    style={{ outline: "none" }}
                  />
                  <area
                    alt=""
                    title=""
                    onClick={this.symcBuySSL}
                    shape="rect"
                    coords="63,58,115,81"
                    style={{ outline: "none" }}
                  />
                </map>
                <a
                  href="https://www.websecurity.symantec.com/ssl-certificate"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#000000",
                    textDecoration: "none",
                    font: "bold 7px verdana,sans-serif",
                    letterSpacing: ".5px",
                    textAlign: "center",
                    margin: "0px",
                    padding: "0px"
                  }}
                >
                  ABOUT SSL CERTIFICATES
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
