import React, { useState, useEffect } from "react";

export default function Indicator(props) {
  return (
    <li>
      <div class="tag">{props.name}</div>
      <br />
      {Array.isArray(props.value) ? (
        <ul>
          {props.value.map(val => (
            <li>{val}</li>
          ))}
        </ul>
      ) : (
        <div>
          {props.name === "NSF Count"
            ? props.value
            : `$${props.value.toLocaleString()}`}
        </div>
      )}
    </li>
  );
}
