import React, { useState, useEffect } from "react";
import Info from "./Info";
import {
  expandInfo,
  showAmountEdit,
  showTermEdit,
  showCommentEdit,
  sponsor,
  deny,
  onAccountTypeChange
} from "../helpers/applications.js";
import moment from "moment";
import { loadLoanData } from "../util/partner_util";
import cloneDeep from "lodash.clonedeep";

export default function OpenLoan(props) {
  const [rawTransactionData, setRawTransactionData] = useState(null);
  const [formattedTransactionData, setFormattedTransactionData] = useState(
    null
  );
  const [activeTab, setActiveTab] = useState("graph");
  const expandedInfo = props.expandedInfoId === props.loan.id;

  useEffect(() => {
    if (expandedInfo && !formattedTransactionData) {
      loadLoanData(props.loan.underwriting_decision_id)
        .then(response => {
          if (response) {
            response
              .clone()
              .json()
              .then(res => {
                let formatted_loan_data = calculateLoanBalance(
                  res.loan_data,
                  "Depository"
                );
                setRawTransactionData(res.loan_data);
                setFormattedTransactionData(formatted_loan_data);
              });
          }
        })
        .catch(err => alert(err));
    }
  }, [expandedInfo]);

  const onAccountChange = account_id => {
    let calculatedTransactionData = calculateLoanBalance(
      rawTransactionData,
      account_id
    );
    setFormattedTransactionData(calculatedTransactionData);
  };

  const calculateLoanBalance = (loan_data, account_id) => {
    let loan_data_copy = cloneDeep(loan_data);
    let account = loan_data_copy.accounts.filter(
      x => x.account_id === account_id
    )[0];
    let account_balance = account.balance;
    let filteredTransactions = loan_data_copy.plaid.transactions.filter(
      x => account_id === "Depository" || x.account_id === account_id
    );
    loan_data_copy.plaid.transactions = filteredTransactions.map(
      (transaction, idx) => {
        transaction["amount"] *= -1;
        if (idx !== 0) {
          account_balance -= filteredTransactions[idx - 1]["amount"];
        }
        transaction["account_balance"] = account_balance;
        return transaction;
      }
    );
    return loan_data_copy;
  };

  return (
    <div className="loan" id={props.loan.id}>
      <div
        className={`loan__summary ${expandedInfo ? "selected-loan" : ""}`}
        id="dashboard_summary"
      >
        <div
          className="loan__summary--score"
          onClick={() => props.expandInfo(props.loan.id)}
        >
          <div className={`${props.loan.late ? "red" : "green"}`}></div>
          <div className="marker"></div>
        </div>

        <div
          className="loan__summary--id"
          onClick={() => props.expandInfo(props.loan.id)}
        >
          {props.loan.id}
        </div>
        <div className="loan__summary--name">{props.loan.name}</div>
        <div className="loan__summary--date">
          {moment(props.loan.applied).format("MM/DD/YY")}
        </div>
        <div className="loan__summary--amount">${props.loan.cash_advanced}</div>
        <div className="loan__summary--currbal">
          ${props.loan.current_balance}
        </div>
        <div className="loan__summary--nextpay">
          {moment(
            props.loan.payments.sort((a, b) => moment(a.due) - moment(b.due))[0]
              .due
          ).format("MM/DD/YY")}
        </div>
        <div className="loan__summary--comment">
          <div
            className="comment"
            id="current_comment_<%= open_loan.underwriting_decisions.last.id %>"
            onClick="dashboardShowCommentEdit(<%= open_loan.underwriting_decisions.last.id %>)"
          >
            {" "}
            {props.comment || "No Comment"}
          </div>
        </div>
      </div>

      <div className={`loan__info ${expandedInfo ? "expanded" : ""}`}>
        <div className="loan__info--indicators" id="dashboard_indicators">
          <h2>Summary</h2>
          <ul>
            <li>
              <div className="tag">Reserve Used: </div>
              <div>${props.loan.loan_reserve_used.toFixed(2)}</div>
            </li>
            <li>
              <div className="tag">Applied</div>
              <div>{moment(props.loan.applied).format("MM/DD/YY")}</div>
            </li>
            <li>
              <div className="tag">Accepted</div>
              <div>{moment(props.loan.accepted).format("MM/DD/YY")}</div>
            </li>
            <li>
              <div className="tag">Funded</div>
              <div>
                {props.loan.funded
                  ? moment(props.loan.funded).format("MM/DD/YY")
                  : "Not Funded"}
              </div>
            </li>
            <li>
              <div className="tag">Payment status</div>
              <div>
                {props.loan.late ? (
                  <span className="red"> Behind </span>
                ) : (
                  "On time"
                )}
              </div>
            </li>
          </ul>
          <br />
          <h2>Unpaid Payments</h2>
          <ul>
            {props.loan.payments
              .filter(x => !x.paid)
              .map(payment => {
                return (
                  <li className="dashboard">
                    <div
                      className={
                        payment.processing ? "db_tag orange" : "db_tag"
                      }
                    >
                      {`ID: ${payment.id}`}
                    </div>
                    <div>
                      {payment.processing ? (
                        <span className="orange db_text">
                          PROCESSING
                          <br />
                        </span>
                      ) : null}
                      <span className="db_text">
                        <span className="navy">Amt:</span> $
                        {payment.expected_amt}
                      </span>
                      <br />
                      <span className="db_text">
                        <span className="navy">Due:</span>{" "}
                        <i>{moment(payment.due).format("ddd, MM/DD/YY")}</i>
                      </span>
                      <br />
                      {!payment.processing ? (
                        <span className="db_text">
                          <span className="navy">Orig Due:</span>{" "}
                          <i>
                            {moment(payment.original_due).format("MM/DD/YY")}
                          </i>
                        </span>
                      ) : null}
                    </div>
                  </li>
                );
              })}
          </ul>

          {props.loan.payments.filter(payment => payment.paid).length > 0 ? (
            <>
              <h2 className="navy">Paid Payments</h2>
              <ul>
                {props.loan.payments
                  .filter(payment => payment.paid)
                  .sort((a, b) => moment(a.due) - moment(b.due))
                  .map(payment => (
                    <li className="dashboard">
                      <div className="navy">ID: {payment.id}</div>
                      <div>
                        <span className="navy db_text">
                          Paid: ${payment.paid_amt.toFixed(2)}
                        </span>
                        <br />
                        <span className="navy db_text">
                          Date: {moment(payment.paid).format("ddd, MM/DD/YY")}
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </>
          ) : null}
        </div>

        <div className="loan__info--charts">
          {expandedInfo && formattedTransactionData ? (
            <Info
              application={props.loan}
              accounts={formattedTransactionData.accounts}
              plaid={formattedTransactionData.plaid}
              onAccountChange={onAccountChange}
              activeTab={activeTab}
            />
          ) : (
            <div className="info" id={`info_${props.loan.id}`}>
              <div className="graph" id={`graph_${props.loan.id}`}>
                <h2>Account Balance Graph</h2>
                <div
                  className="graph_loading"
                  id={`graph_loading_${props.loan.id}`}
                >
                  <img
                    src="/other/preloader_8.gif"
                    style={{ width: "128px", display: "block" }}
                  />
                </div>
              </div>
              <div className="history hidden">
                <h2>Transaction History</h2>
                <div></div>
              </div>
            </div>
          )}
          <div className="tabs">
            <div
              className={`tabs__graph ${
                activeTab === "graph" ? "active-tab" : ""
              } mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect`}
              onClick={() => setActiveTab("graph")}
            >
              <i className="fas fa-chart-area"></i>
            </div>
            <div
              className={`tabs__history ${
                activeTab === "history" ? "active-tab" : ""
              } mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect`}
              onClick={() => setActiveTab("history")}
            >
              <i className="fas fa-history"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
