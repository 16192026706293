import React, { useState, useEffect } from "react";
import Transaction from "./Transaction";
import BalanceGraph from "./BalanceGraph";
import * as d3 from "d3";
import _ from "underscore";
import moment from "moment";

export default function Info(props) {
  const [transactionPage, setTransactionPage] = useState(0);
  const [transactionFilter, setTransactionFilter] = useState("all");
  const [filteredTransactions, setFilteredTransactions] = useState(
    props.plaid.transactions
  );
  const [selectedAccount, setSelectedAccount] = useState(
    props.accounts[0].account_id
  );

  const transactionsPerPage = 15;

  useEffect(() => {
    props.onAccountChange(selectedAccount);
    setTransactionPage(0);
  }, [selectedAccount]);

  useEffect(() => {
    if (transactionFilter === "all") {
      setFilteredTransactions(props.plaid.transactions);
      setTransactionPage(0);
    } else if (transactionFilter === "green") {
      setFilteredTransactions(
        props.plaid.transactions.filter(x => x.trans_line === "green")
      );
      setTransactionPage(0);
    }
  }, [transactionFilter]);

  return (
    <div class="info" id={`info_${props.application.id}`}>
      <div className={`graph ${props.activeTab === "graph" ? "" : "hidden"}`}>
        <h2>
          {props.application.organization_name === "Access Ventures"
            ? `${props.application.name.split(" ")[0]}'s ${
                props.plaid.account_type
              } `
            : ""}
          Account Balance Graph
        </h2>
        <BalanceGraph plaid={props.plaid} application={props.application} />
      </div>
      <div
        className={`history ${props.activeTab === "history" ? "" : "hidden"}`}
      >
        <h2>
          {props.application.organization_name === "Access Ventures"
            ? `${props.application.name.split(" ")[0]}'s ${
                props.plaid.account_type
              } `
            : ""}
          Transaction History
        </h2>
        <div>
          <div className="account-selector">
            <select
              name="accounts"
              id={`accounts-${props.application.id}`}
              onChange={e => {
                setSelectedAccount(e.target.value);
              }}
            >
              {props.accounts.map(acct => (
                <option value={acct.account_id}>{acct.name}</option>
              ))}
            </select>
          </div>

          <div
            className="account-selector"
            style={{ width: "250px", marginTop: "10px" }}
          >
            <select
              name="transaction-filter"
              onChange={e => {
                setTransactionFilter(e.target.value);
              }}
            >
              <option value="all">All</option>
              <option value="green">Income</option>
            </select>
          </div>

          <div
            className="account-table"
            id={`account-table-${props.application.id}`}
            page="0"
          >
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th className="name">Name</th>
                  <th className="location">Location</th>
                  <th className="account">Account</th>
                  <th style={{ display: "none" }}>type_controller</th>
                  <th className="amount">Amount</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions
                  .slice(
                    transactionPage * transactionsPerPage,
                    (transactionPage + 1) * transactionsPerPage
                  )
                  .map(transaction => (
                    <Transaction
                      transaction={transaction}
                      application={props.application}
                    />
                  ))}
              </tbody>
            </table>
          </div>
          <div className="account-pagination">
            <button
              disabled={transactionPage === 0}
              className="previous-page pagination mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect"
              onClick={() =>
                setTransactionPage(prevPageNumber => prevPageNumber - 1)
              }
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <h2>
              {transactionPage + 1}/
              {Math.ceil(filteredTransactions.length / transactionsPerPage)}
            </h2>
            <button
              className="next-page pagination mdl-button mdl-js-button mdl-button--accent mdl-js-ripple-effect"
              disabled={
                transactionPage >=
                Math.floor(filteredTransactions.length / transactionsPerPage)
              }
              onClick={() =>
                setTransactionPage(prevPageNumber => prevPageNumber + 1)
              }
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
