let env;

if (
  window &&
  window.location &&
  window.location.host.includes("figloans.com")
) {
  env = "production";
} else {
  env = "sandbox";
}

export const environment = env;
